// modules
import React, { useEffect, useState } from "react";
import "./HowToPlay.css";

// context
import useGame from "../../context/game";

// components
import ModalWrapper from "../Modal";
import { Cell } from "../Game/Grid";

const HowToPlay = ({ show, onClose }) => {
  const { mainWord } = useGame();
  const [isRevealing, setIsRevealing] = useState(false);

  useEffect(() => {
    if (show) {
      setIsRevealing(true);
    } else {
      setIsRevealing(false);
    }
  }, [show]);

  if (!mainWord) return;
  return (
    <ModalWrapper show={show} onClose={onClose}>
      <div className="game-guide_content">
        <h1 className="my-6 text-lg font-bold">
          Welcome to the 2022 World Cup Wordle!
        </h1>
        <article>
          <p className="text-sm text-center">
            There will be a new player name of 5 or 6 characters in length every
            day. Each guess must be a player named in the 2022 World Cup Squads.
            Press the enter button to submit your answer. After each guess, the
            colour of the tiles will change to show which letters if any you got
            correct. Green for a correct position and orange for an incorrect
            letter position. You can get a clue along the way to tell you the
            position the player plays and the country that they play for to give
            you some help.
          </p>

          <div className="flex justify-center mb-1 mt-4">
            <Cell
              isRevealing={isRevealing}
              isCompleted={true}
              value="M"
              status="correct"
            />
            <Cell value="E" />
            <Cell value="S" />
            <Cell value="S" />
            <Cell value="I" />
          </div>

          <p className="text-sm text-center">
            The letter M is in the word and in the correct spot.
          </p>

          <div className="flex justify-center mb-1 mt-4">
            <Cell value="M" />
            <Cell value="O" />
            <Cell
              isRevealing={isRevealing}
              isCompleted={true}
              value="U"
              status="present"
            />
            <Cell value="N" />
            <Cell value="T" />
          </div>
          <p className="text-sm text-center">
            The letter U is in the word but in the wrong spot.
          </p>

          <div className="flex justify-center mb-1 mt-4">
            <Cell value="X" />
            <Cell value="H" />
            <Cell value="A" />
            <Cell
              isRevealing={isRevealing}
              isCompleted={true}
              value="K"
              status="absent"
            />
            <Cell value="A" />
          </div>
          <p className="text-sm text-center">
            The letter K is not in the word in any spot.
          </p>
        </article>
      </div>
    </ModalWrapper>
  );
};

export default HowToPlay;
