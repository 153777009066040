// modules
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { LEAGUES } from "../utils/constants";
import "./Game.css";

// context
import useGame from "../context/game";

// components
import GameMainBox from "../components/Game/GameMainBox";

const Game = () => {
  const { currentGame, newGameState, setShowHelpbox, oldGameState } = useGame();
  const [isGamePage, setIsGamePage] = useState(false);
  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    if (!params.gameName && location.pathname !== "/") return;
    const _gameNo = new URLSearchParams(location?.search).get("game");
    if (_gameNo && !isNaN(_gameNo) && _gameNo > 0) {
      if (location.pathname === "/") {
        oldGameState(LEAGUES["world-cup"], _gameNo);
        return;
      }
      setIsGamePage(true);
      oldGameState(LEAGUES[params.gameName], _gameNo);
    } else {
      if (location.pathname === "/") {
        newGameState(LEAGUES["world-cup"]);
        return;
      }
      setIsGamePage(true);
      newGameState(LEAGUES[params.gameName]);
    }

    // eslint-disable-next-line
  }, [params, location]);

  useEffect(() => {
    if (currentGame) {
      let _noHelp = localStorage.getItem(`${currentGame.id}-help`);
      if (!_noHelp) {
        localStorage.setItem(`${currentGame.id}-help`, true);
        setShowHelpbox(true);
      }
    }
    // eslint-disable-next-line
  }, [currentGame]);

  return (
    <main
      className={`game_section ${
        isGamePage ? "game-page" : ""
      } container flex flex-col justify-between`}
    >
      <GameMainBox isGamePage={isGamePage} />
    </main>
  );
};

export default Game;
