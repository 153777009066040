/* eslint-disable */
// modules
import {
  useState,
  useContext,
  createContext,
  useMemo,
  useCallback,
} from "react";
import { addDays, differenceInDays } from "date-fns";
import { unicodeSplit, unicodeLength } from "../libs/words";
import { fetchGameInfo } from "../utils/data.service";
import { hasDateChanged } from "../utils/functions";
import { MAX_CHALLENGES } from "../utils/config";

const GameContext = createContext();

export const GameProvider = ({ children }) => {
  const [gameNo, setGameNo] = useState(null);
  const [lastGameNo, setLastGameNo] = useState(null);
  const [mainWord, setMainWord] = useState("");
  const [clues, setClues] = useState("");
  const [currentGame, setCurrentGame] = useState(null);
  const [guessList, setGuessList] = useState([]);
  const [guessDistribution, setGuessDistribution] = useState([]);
  const [winDistribution, setWinDistribution] = useState([]);
  const [guessCount, setGuessCount] = useState(1);
  const [clueCount, setClueCount] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [gameWinLose, setGameWinLose] = useState({ win: false, lose: false });
  const [showStats, setShowStats] = useState(false);
  const [userStatsObj, setUserStatsObj] = useState(null);
  const [gameEnded, setGameEnded] = useState(null);
  const [showHelpbox, setShowHelpbox] = useState(false);
  const [lastGame, setLastGame] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const [gameTimer, setGameTimer] = useState(null);

  const newGameState = useCallback((gameId) => {
    setLoading(true);
    setClueCount(0);
    setGameNo(null);
    setLastGameNo(null);
    setGameOver(false);
    if (!gameId) {
      setCurrentGame(null);
      setGuessList([]);
      setGameOver(false);
      return;
    }
    setLastGame(false);

    // fetch
    const gameData = fetchGameInfo(gameId);
    setCurrentGame(gameData);

    showStatsVal(gameId);

    const _todaySolution = calcSolution(
      gameData.players,
      gameData.gameStartDate
    );
    setClues(_todaySolution.clues);
    setGameNo(_todaySolution["gameNo"]);
    setLastGameNo(_todaySolution["gameNo"] - 1);

    const userGameState = localStorage.getItem(`gameState-${gameId}`);

    let userState;
    if (userGameState) userState = JSON.parse(userGameState);
    if (userGameState && userState) {
      // if user have already played the game
      const {
        gameFinished,
        solution,
        userGuesses,
        userClueCount,
        gameOver,
        gameWin,
        gameLose,
        timerStart,
        gameTimer,
      } = userState;

      const dateChangedSeconds = hasDateChanged(gameFinished, new Date());
      const dateChangedTimer = hasDateChanged(timerStart, new Date());

      if (
        (!gameFinished && dateChangedTimer) ||
        (gameFinished && dateChangedSeconds)
      ) {
        // after timer reset
        setGuessList([]);
        setGameOver(false);
        setWinDistribution([]);

        const _userState = {
          solution: _todaySolution.solution,
          gameOver: false,
          gameWin: false,
          gameLose: false,
          userGuesses: [],
          userClueCount: 0,
          gameFinished: null,
          timerStart: new Date(),
          gameTimer: null,
        };
        setMainWord(_todaySolution.solution);

        const userGameStats = localStorage.getItem(`gameStats-${gameId}`);
        if (userGameStats) {
          let _userStats = {
            ...JSON.parse(userGameStats),
            winDistribution: [],
          };
          localStorage.setItem(
            `gameStats-${gameId}`,
            JSON.stringify(_userStats)
          );
        }

        localStorage.setItem(`gameState-${gameId}`, JSON.stringify(_userState));
        setLoading(false);
      } else {
        // if no timer reset
        let _guess_list = [];
        let _guessCount = 1;
        let _winDistribution = [];

        const initialGuessList = (solutionWord, guessWord, idx, length) => {
          let guessCheck;
          if (solutionWord === guessWord) {
            guessCheck = new Array(unicodeLength(solutionWord)).fill("correct");
          } else {
            guessCheck = getStatuses(solutionWord, guessWord);
          }

          _guess_list[idx] = {
            guessWord: guessWord,
            guessCount: _guessCount,
            guessCheck,
          };
          _guessCount++;

          _winDistribution.push(0);

          if (idx === length - 1) {
            setGuessList(_guess_list);
            setGuessCount(_guessCount);

            const userGameStats = localStorage.getItem(`gameStats-${gameId}`);
            if (userGameStats) {
              const _userStats = JSON.parse(userGameStats);
              setWinDistribution(_userStats.winDistribution);
              setUserStatsObj(_userStats);
            } else {
              setWinDistribution(_winDistribution);
              setUserStatsObj(null);
            }
          }
        };

        setMainWord(solution);
        setClueCount(userClueCount);
        if (userGuesses.length > 0) {
          userGuesses.forEach((guess, idx, arr) => {
            initialGuessList(solution, guess, idx, arr.length);
          });
        } else {
          setGuessList(_guess_list);
          setGuessCount(_guessCount);
        }

        setGameOver(gameOver);
        setGameWinLose({ win: gameWin, lose: gameLose });
        setGuessDistribution(userGuesses);
        setGameEnded(gameFinished);
        setGameTimer(gameTimer);
        setLoading(false);
      }
    } else {
      // if user playing game for first time
      setGuessList([]);
      setGameOver(false);
      setGuessDistribution([]);
      setWinDistribution([]);
      setGuessCount(1);
      setClueCount(0);
      setGameWinLose({ win: false, lose: false });
      setUserStatsObj(null);
      setGameEnded(null);
      setGameTimer(null);

      let _gameState = {
        solution: _todaySolution.solution,
        userGuesses: [],
        userClueCount: 0,
        gameOver: false,
        gameWin: false,
        gameLose: false,
        gameFinished: null,
        timerStart: new Date(),
        gameTimer: null,
      };

      setMainWord(_todaySolution.solution);

      localStorage.setItem(`gameState-${gameId}`, JSON.stringify(_gameState));

      setLoading(false);
    }
  });

  const oldGameState = useCallback((gameId, game_no) => {
    setLoading(true);
    setGameOver(false);

    if (!gameId) {
      setCurrentGame(null);
      setGuessList([]);
      return;
    }

    showStatsVal(gameId);

    const gameData = fetchGameInfo(gameId);

    const _todaySolution = calcSolution(
      gameData.players,
      gameData.gameStartDate
    );
    const _lastDateSolution = calcSolution(
      gameData.players,
      gameData.gameStartDate,
      game_no
    );
    setClues(_lastDateSolution.clues);
    setGameNo(game_no);
    setLastGameNo(_lastDateSolution["gameNo"] - 1);

    if (_lastDateSolution && game_no) {
      setLastGame(true);
      setClueCount(0);
      setLastGameNo(game_no - 1);

      setCurrentGame(gameData);
      setGameNo(_todaySolution["gameNo"]);
      setGuessList([]);
      setGameOver(false);
      setGuessDistribution([]);
      setWinDistribution([]);
      setGuessCount(1);
      setGameTimer(null);

      setGameWinLose({ win: false, lose: false });
      setUserStatsObj(null);
      setGameEnded(null);

      setMainWord(_lastDateSolution.solution);
      setLoading(false);
    } else {
      newGameState(gameId);
    }
  });

  const addToGuessList = useCallback((word) => {
    if (!mainWord) return;

    // providing clue to user's by highlighting similar info
    let guessCheck;

    if (mainWord === word) {
      guessCheck = new Array(unicodeLength(mainWord)).fill("correct");
    } else {
      guessCheck = getStatuses(mainWord, word);
    }

    // adding player to guess list
    setGuessList([
      ...guessList,
      { guessWord: word, guessCount: guessCount, guessCheck },
    ]);
    setGuessDistribution([...guessDistribution, word]);

    // only for today's game
    if (!lastGame) {
      const _gameState = JSON.parse(
        localStorage.getItem(`gameState-${currentGame?.id}`)
      );
      localStorage.setItem(
        `gameState-${currentGame?.id}`,
        JSON.stringify({
          ..._gameState,
          userGuesses: [...guessDistribution, word],
        })
      );
    }

    // increasing guess count
    setGuessCount((prevVal) => prevVal + 1);

    if (mainWord === word) {
      handleGameWin();

      return;
    }

    // if user's lose the game
    if (guessCount === MAX_CHALLENGES) {
      setWinDistribution([...winDistribution, 0]);
      handleGameLose();

      return;
    }

    // setting win distribution
    setWinDistribution([...winDistribution, 0]);
  });

  const handleGameWin = useCallback(() => {
    setGameWinLose({ win: true, lose: false });
    setGameOver(true);
    if (lastGame) return;

    const userGameState = JSON.parse(
      localStorage.getItem(`gameState-${currentGame?.id}`)
    );

    let _winDistribution = [
      ...winDistribution,
      1,
      ...Array(MAX_CHALLENGES - winDistribution.length - 1).fill(0),
    ];
    setWinDistribution(_winDistribution);

    let userGameStats = localStorage.getItem(`gameStats-${currentGame?.id}`);
    userGameStats = JSON.parse(userGameStats);
    let _userStats;
    if (userGameStats) {
      const { totalFailed, totalTries, currentStreak, bestStreak } =
        userGameStats;
      const _totalTries = totalTries + 1;
      const successRate = ((_totalTries - totalFailed) / _totalTries) * 100;
      _userStats = {
        ...userGameStats,
        totalTries: _totalTries,
        successRate,
        currentStreak: shouldContinueStreak(userGameState.gameFinished)
          ? currentStreak + 1
          : 1,
        bestStreak: Math.max(
          shouldContinueStreak(userGameState.gameFinished)
            ? currentStreak + 1
            : 1,
          bestStreak
        ),
        winDistribution: _winDistribution,
      };
    } else {
      _userStats = {
        totalFailed: 0,
        totalTries: 1,
        successRate: 100,
        currentStreak: 1,
        bestStreak: 1,
        winDistribution: _winDistribution,
      };
    }

    setUserStatsObj(_userStats);

    const _userState = {
      ...userGameState,
      gameOver: true,
      gameWin: true,
      gameLose: false,
      gameFinished: new Date(),
    };
    setGameEnded(new Date());

    localStorage.setItem(
      `gameStats-${currentGame?.id}`,
      JSON.stringify(_userStats)
    );
    localStorage.setItem(
      `gameState-${currentGame?.id}`,
      JSON.stringify(_userState)
    );
  });

  const handleGameLose = useCallback(() => {
    // set gameover and game lose
    setGameWinLose({ win: false, lose: true });
    setGameOver(true);

    if (lastGame) return;

    let userGameStats = localStorage.getItem(`gameStats-${currentGame?.id}`);
    userGameStats = JSON.parse(userGameStats);
    let _userStats;

    if (userGameStats) {
      const { totalFailed, totalTries } = userGameStats;
      const _totalTries = totalTries + 1;
      const _totalFailed = totalFailed + 1;
      const successRate = ((_totalTries - _totalFailed) / _totalTries) * 100;
      _userStats = {
        ...userGameStats,
        totalFailed: _totalTries,
        totalTries: _totalTries,
        successRate,
        currentStreak: 0,
        winDistribution: Array(MAX_CHALLENGES).fill(0),
      };
    } else {
      _userStats = {
        totalFailed: 1,
        totalTries: 1,
        successRate: 0,
        currentStreak: 0,
        winDistribution: Array(MAX_CHALLENGES).fill(0),
        bestStreak: 0,
      };
    }

    setUserStatsObj(_userStats);

    const userGameState = JSON.parse(
      localStorage.getItem(`gameState-${currentGame?.id}`)
    );
    const _userState = {
      ...userGameState,
      gameOver: true,
      gameWin: false,
      gameLose: true,
      gameFinished: new Date(),
    };
    setGameEnded(new Date());

    // updating local storage
    localStorage.setItem(
      `gameStats-${currentGame?.id}`,
      JSON.stringify(_userStats)
    );
    localStorage.setItem(
      `gameState-${currentGame?.id}`,
      JSON.stringify(_userState)
    );
  });

  const shouldContinueStreak = (lastGameFinished) => {
    if (!lastGameFinished) return false;
    let _today = new Date();
    let lastGameDate = new Date(lastGameFinished);
    _today.setHours(0, 0, 0, 0);
    lastGameDate.setHours(0, 0, 0, 0);

    const diffInDays = differenceInDays(_today, lastGameDate);

    if (diffInDays > 1) return false;
    else return true;
  };

  // calculate solution
  const calcSolution = (WORDS, GAME_START_DATE, gameNo = false) => {
    // August 23, 2022 Game Epoch
    const epoch = new Date(GAME_START_DATE);
    const start = new Date(epoch);

    const today = new Date();
    const baseDate =
      gameNo && typeof Number(gameNo) === "number" && Number(gameNo) > 0
        ? addDays(start, Number(Number(gameNo) - 1))
        : today;

    baseDate.setHours(0, 0, 0, 0);
    start.setHours(0, 0, 0, 0);
    let index = 0;
    gameNo = differenceInDays(baseDate, start) + 1;

    while (start < baseDate) {
      index++;
      start.setDate(start.getDate() + 1);
    }

    const nextDay = new Date(today);
    nextDay.setDate(today.getDate() + 1);

    const solutionPlayer = WORDS[index % WORDS.length];

    return {
      solution: solutionPlayer.name.toUpperCase(),
      clues: {
        position: groupPositions(solutionPlayer.position),
        teamFlag: solutionPlayer.team_flag,
        teamName: solutionPlayer.team_name,
      },
      solutionIndex: index,
      tomorrow: nextDay.valueOf(),
      isToday: baseDate === today,
      gameNo: gameNo,
    };
  };

  // Group positions
  const groupPositions = (pos) => {
    const gkRegx = /^GK$/;
    const defRegx = /^(SW|RB|RWB|LB|LWB|CB|D)$/;
    const midRegx = /^(DM|CDM|CAM|CM|LM|RM|AM|M)$/;
    const forwardRegx = /^(LW|LWM|RW|RWM|ST|FW|CF|A)$/;

    if (gkRegx.test(pos)) {
      return "Goalkeeper";
    } else if (defRegx.test(pos)) {
      return "Defender";
    } else if (midRegx.test(pos)) {
      return "Midfielder";
    } else if (forwardRegx.test(pos)) {
      return "Forward";
    } else {
      return pos;
    }
  };

  // show previous stats
  const showStatsVal = (gameId) => {
    let userGameStats = localStorage.getItem(`gameStats-${gameId}`);
    userGameStats = JSON.parse(userGameStats);

    if (userGameStats) {
      setUserStatsObj(userGameStats);
    } else {
      setUserStatsObj(null);
    }
  };

  // get statuses
  const getStatuses = (solution, guess) => {
    const splitSolution = unicodeSplit(solution);
    const splitGuess = unicodeSplit(guess);

    const solutionCharsTaken = splitSolution.map((_) => false);

    const statuses = new Array(guess.length);

    // handle all correct cases first
    splitGuess.forEach((letter, i) => {
      if (letter === splitSolution[i]) {
        statuses[i] = "correct";
        solutionCharsTaken[i] = true;
      }
    });

    splitGuess.forEach((letter, i) => {
      if (statuses[i]) return;

      if (!splitSolution.includes(letter)) {
        // handles the absent case
        statuses[i] = "absent";
        return;
      }

      // now we are left with "present"s
      const indexOfPresentChar = splitSolution.findIndex(
        (x, index) => x === letter && !solutionCharsTaken[index]
      );

      if (indexOfPresentChar > -1) {
        statuses[i] = "present";
        solutionCharsTaken[indexOfPresentChar] = true;
        return;
      } else {
        statuses[i] = "absent";
        return;
      }
    });

    return statuses;
  };

  // handle give up
  const handleGiveup = useCallback(() => {
    if (!currentGame) return;

    let _guessList = [...guessList];
    let _guessDistribution = [...guessDistribution];
    let _empties = MAX_CHALLENGES - guessList.length;

    new Array(_empties).fill("*").forEach((guess) => {
      let guessWord = guess.repeat(mainWord.length);
      _guessList.push({
        guessWord: guessWord,
        guessCount: guessCount,
        guessCheck: new Array(mainWord.length).fill("absent"),
      });
      _guessDistribution.push(guessWord);

      setGuessCount((prev) => prev + 1);
    });

    setGuessList(_guessList);
    setGuessDistribution(_guessDistribution);

    // only for today's game
    if (!lastGame) {
      const _gameState = JSON.parse(
        localStorage.getItem(`gameState-${currentGame?.id}`)
      );
      localStorage.setItem(
        `gameState-${currentGame?.id}`,
        JSON.stringify({
          ..._gameState,
          userGuesses: _guessDistribution,
        })
      );
    }

    setWinDistribution(new Array(MAX_CHALLENGES).fill(0));
    handleGameLose();
  });

  const handleTimer = useCallback((timeSec, game_id) => {
    if (!game_id) return;
    if (lastGame) {
      setGameTimer(timeSec);
      return;
    }

    const userGameState = JSON.parse(
      localStorage.getItem(`gameState-${game_id}`)
    );
    const _userState = {
      ...userGameState,
      gameTimer: timeSec,
    };

    localStorage.setItem(`gameState-${game_id}`, JSON.stringify(_userState));

    setGameTimer(timeSec);
  });

  const giveClue = useCallback((game_id) => {
    if (!game_id) return;
    if (lastGame) {
      setClueCount(clueCount + 1);
      return;
    }
    const userGameState = JSON.parse(
      localStorage.getItem(`gameState-${game_id}`)
    );
    const _userState = {
      ...userGameState,
      userClueCount: clueCount + 1,
    };

    localStorage.setItem(`gameState-${game_id}`, JSON.stringify(_userState));

    setClueCount(clueCount + 1);
  });

  const value = useMemo(
    () => ({
      currentGame,
      mainWord,
      clues,
      clueCount,
      guessDistribution,
      guessList,
      guessCount,
      gameTimer,
      handleTimer,
      gameOver,
      gameWinLose,
      showStats,
      setShowStats,
      setGameOver,
      userStatsObj,
      winDistribution,
      gameEnded,
      showHelpbox,
      setShowHelpbox,
      lastGame,
      gameNo,
      oldGameState,
      newGameState,
      lastGameNo,
      setCurrentGame,
      loading,
      showContactForm,
      setShowContactForm,
      addToGuessList,
      giveClue,
      handleGameLose,
      handleGiveup,
    }),
    [
      currentGame,
      mainWord,
      clues,
      clueCount,
      gameTimer,
      guessList,
      guessCount,
      guessDistribution,
      gameOver,
      gameWinLose,
      showStats,
      setShowStats,
      setGameOver,
      userStatsObj,
      winDistribution,
      gameEnded,
      showHelpbox,
      setShowHelpbox,
      lastGame,
      gameNo,
      lastGameNo,
      setCurrentGame,
      loading,
      addToGuessList,
      handleGiveup,
      giveClue,
      handleGameLose,
      handleTimer,
      newGameState,
      oldGameState,
      showContactForm,
    ]
  );

  return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
};

const useGame = () => useContext(GameContext);
export default useGame;
